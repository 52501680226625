import React, { useEffect, useState } from 'react';
import '../styles/index.css';

function Index() {

  return (
    <main>
      <h1>Welcome to OpenZ</h1>      
    </main>
  );
}

export default Index;
